import React from 'react'

class Footer extends React.Component {
    render() {
        return (
            <div id="footer">
                <div className="inner" >
                    <ul className="icons">
                        <li>
                            <a href="https://twitter.com/juliejonak" target="_blank"  rel="noopener noreferrer" className="icon fa-twitter">
                                <span className="label">Twitter</span>
                            </a>
                        </li>

                        <li>
                            <a href="https://github.com/juliejonak"  target="_blank" rel="noopener noreferrer" className="icon fa-github">
                                <span className="label">Github</span>
                            </a>
                        </li>

                        <li>
                            <a href='https://www.linkedin.com/in/juliejonak/' target="_blank" rel="noopener noreferrer" className="icon fa-linkedin">
                                <span className="label">LinkedIn</span>
                            </a>
                        </li>

                        <li>
                            <a href="mailto:juliejonak10@gmail.com?Subject=Can we schedule a meeting?&body=You sound like a great candidate for our open developer position."  target="_blank" rel="noopener noreferrer" className="icon fa-envelope-o">
                                <span className="label">Email</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="copyright">
                        <li>&copy; Julie Jonak</li>
                        <p>Photo by Aron Visuals on Unsplash</p>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Footer
