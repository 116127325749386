import React from 'react'

import Footer from './Footer'

class Header extends React.Component {
    render() {
        return (
            <header id="header" style={{padding: "1.5em 2em 0.5em 2em"}}>
                <div className="inner">
                    <h1><strong>Julie Jonak</strong>, a full stack developer<br />
                    & business conference organizer <br></br>
                    taught by <a href="https://lambdaschool.com" target="_blank"  rel="noopener noreferrer">Lambda School</a>.</h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
